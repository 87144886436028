import React, { FC, useState } from "react";
import { Helmet } from "react-helmet-async";

const PageFaqs: FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const questions = [
    {
      question: "Q1. How do I place an order?",
      answer: "To place an order, simply browse our products, add your desired items to the shopping cart, and proceed to checkout. Follow the prompts to enter your shipping information and payment details.",
    },
    {
      question: "Q2. Can I modify or cancel my order?",
      answer: "Once an order is placed, it cannot be modified. Please double-check your order before confirming the purchase. We are unable to cancel orders once they have been shipped.",
    },
    {
      question: "Q3. What payment methods do you accept?",
      answer: "All transactions are securely processed through reputable payment gateways.",
    },
    {
      question: "Q4. How can I track my order?",
      answer: "Once your order is shipped, you will receive a confirmation email with a tracking number. You can track the status of your shipment using the provided link.",
    },
    {
      question: "Q5. What is your return policy?",
      answer: "Our return policy is outlined in detail. Please review it for information on eligibility, initiating returns, and refund processes.",
    },
    {
      question: "Q7. How can I contact customer support?",
      answer: (
        <div>
          For any questions or assistance, you can reach our customer support team at{" "}
          <a href="mailto:info@ssjewel.co.in">info@ssjewel.co.in</a> or{" "}
          <a href="tel:+919909457875">(+91) 990 945 7875</a>.
        </div>
      ),
    },
    {
      question: "Q8. Are my personal and payment details secure?",
      answer: "Yes, we take the security of your information seriously. Our website uses secure socket layer (SSL) encryption, and we do not store payment information on our servers.",
    },
    {
      question: "Q9. Can I change my account information?",
      answer: "Yes, you can update your account information, including your shipping address and password, by logging into your account on our website.",
    },
    {
      question: "Q10. What should I do if I forget my password?",
      answer: "If you forget your password, click on the 'Forgot Password' link on the login page, and instructions for resetting your password will be sent to your registered email address.",
    },
  ];

  const handleAccordionClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <Helmet>
        <title>Frequently asked questions || SS Jewels</title>
      </Helmet>

      <section className="pt-16 pb-16">
        <div className="lagle-container">
          <div className="lagle-heading">Frequently asked questions</div>

          <div className="faqs-accordion">
            {questions.map((item, index) => (
              <div className="acc-item" key={index}>
                <div
                  className={`acc-heding ${ openIndex === index ? "" : "active"}`}
                  onClick={() => handleAccordionClick(index)}
                >
                  {item.question}
                  <span className="down-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                    </svg>
                  </span>
                </div>

                <div
                  className={`acc-data ${openIndex === index ? "" : "active"}`}
                >
                  <div className="faqs-data">{item.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PageFaqs;
