import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet-async";
// import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionPromo1 from "components/SectionPromo1";
// import SectionHero2 from "components/SectionHero/SectionHero2";
// import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionSliderProductCard2 from "components/SectionSliderProductCard2";
// import DiscoverMoreSlider from "components/DiscoverMoreSlider";
// import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
// import SectionPromo2 from "components/SectionPromo2";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
// import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay2 from "components/SectionClientSay/SectionClientSay2";
// import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
// import Heading from "components/Heading/Heading";
//import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import SectionBanner from './SectionBanner';
// import SectionBlogSlider from '../BlogPage/SectionBlogSlider';
import SectionVideo from './SectionVideo';
// import SectionAd from './SectionAd';
// import SectionClients from './SectionClients';
// import ProductQuickView from 'components/ProductQuickView';
import ProductQuickView3 from 'components/ProductQuickView3';

function PageHome() {

  const [page, setPage] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const sendRequestRef = useRef(true);

  useEffect(() => {        
    setLoading(true);        
    sendRequestRef.current = true;
    setPage(1);
  },[]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(sendRequestRef.current === true) {
          sendRequestRef.current = false;
          setLoading(true);            
          setPage((prevPage:any) => prevPage + 1);
          sendRequestRef.current = true;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 375 && !loading) {
        if(page != '')
        {
          fetchData();
        }
      }
    };
      
    if((page == 1 || page == 2))
    {
      if(page != '')
      {
        fetchData();
      }
    }
    
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, page]);

  useEffect(() => {
    if(page == 11)
    {
      console.log('in page',page)
      const script = document.createElement('script');
      script.id = 'EmbedSocialHashtagScript';
      script.src = 'https://embedsocial.com/cdn/ht.js';

      if (!document.getElementById('EmbedSocialHashtagScript')) {
        document.getElementsByTagName('head')[0].appendChild(script);
      }

      return () => {
        // Cleanup script when the component is unmounted
        const scriptElement = document.getElementById('EmbedSocialHashtagScript');
        if (scriptElement) {
          scriptElement.remove();
        }
      };
    }
  }, [page]);
  
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>SS Jewels</title>
        <meta property="og:site_name" content="SS Jewels" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content='Welcome to Shree Shree Hare Krishna Diamonds. Shree Shree Hare Krishna Diamonds is a renowned name in the world of diamonds, symbolizing excellence and integrity. With a legacy of craftsmanship spanning decades, they offer exquisite diamonds of unparalleled quality, catering to the most discerning clientele worldwide. Their commitment to ethically sourced diamonds and exceptional customer service sets them apart as a trusted and esteemed provider in the industry.' />
        <meta property="og:title" content="SS Jewels" />
        <meta property="og:image" itemProp="image" content="https://new.pmcommu.in/og-img.jpg" />
        <meta property="og:image:url" content="https://new.pmcommu.in/og-img.jpg" />
        <meta property="og:image:secure_url" content="https://new.pmcommu.in/og-img.jpg" />
        <meta property="og:url" content={window.location.origin} />
      </Helmet>

      {/* SECTION HERO */}
      {/* { page > 1 && <SectionHero2 /> } */}
      { page > 1 && <SectionBanner /> }

      {/* --- skeletant-design --- */}
      <div className="skeletant-target hidden">
        <div className="skeletant-design ld-no-radius h-[380px] w-full mb-8"></div>
      </div>
      {/* --- skeletant-design --- */}

      {/* <SectionClients /> */}

      <div className="container relative space-y-20 my-16">

        { page > 2 && <SectionSliderCategories /> }

        { page > 3 && 
          <SectionSliderProductCard
            data={[
              PRODUCTS[4],
              SPORT_PRODUCTS[5],
              PRODUCTS[7],
              SPORT_PRODUCTS[1],
              PRODUCTS[6],
            ]}
          />
        }

        {/* <SectionAd /> */}
      </div>

      <div className="container relative space-y-20 my-16">
        { page > 4 && <SectionVideo /> }

        { page > 5 && <SectionGridFeatureItems /> }

        { page > 6 && <ProductQuickView3  /> }

        { page > 7 && 
          <SectionSliderProductCard2
            heading="Best Sellers"
            // subHeading="Best selling of the month"
          />
        }


        { page > 8 && <SectionClientSay2 /> }




        {/* { page > 7 &&
          <>
            <SectionBlogSlider />
            <ButtonSecondary>Show all blog articles</ButtonSecondary>
          </> 
        } */}


        {/* { page > 4 && 
          <div className="hidden mt-16 lg:mt-16">
            <DiscoverMoreSlider />
          </div>
        } */}

        {/* <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700"> */}
          {/* <div className='hidden'>
            { page > 5 && <SectionHowItWork /> }
          </div> */}
        {/* </div> */}

        {/* { page > 6 && <SectionPromo2 /> } */}

        {/* { page > 7 && <SectionSliderLargeProduct cardStyle="style2" /> } */}

        {/* SECTION */}
        {/* <SectionPromo1 /> */}

        {/* SECTION */}
        {/* <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <SectionGridMoreExplore />
        </div> */}
            
        {/* { page > 8 && <SectionSliderCategories /> } */}

        {/* <SectionPromo3 /> */}

        {/* { page > 10 && 
        <div className="relative pt-16 pb-0 lg:pt-16 lg:pb-0">
          <BackgroundSection />
          <div>
            <div className="relative z-1 text-center text-custom-golden dark:text-custom-golden text-3xl md:text-4xl font-semibold">
                Instagram Feed
            </div>
           <div style={{paddingBottom: "30px"}} className="embedsocial-hashtag" data-ref="f57df33976844ee7935c83d2f44805f998f44d2b"></div>
            <div className="flex mt-16 justify-center">
            </div>
          </div>
        </div>
        } */}
      </div>
    </div>
  );
}

export default PageHome;
