import React, { FC } from "react";
import { Helmet } from "react-helmet-async";


const PagePrivacy: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy || SS Jewels</title>
      </Helmet>

      <section className="pt-16 pb-16">
          <div className="lagle-container">
              <div className="lagle-heading">Privacy Policy</div>
              <div className="legal-content">
                
                    <div className="main-sub-lable">1. Introduction</div>
                    <p>This Privacy Policy explains how Shree Shree Hare Krishna Diamonds collects, uses, and protects the personal information you provide when using our website.</p>

                    <div className="main-sub-lable">2. Information We Collect</div>
                    <ul className="legal-ul">
                        <li>We may collect personal information, such as your name, address, email address, and phone number, when you create an account, place an order, or contact us.</li>
                        <li>We do not store payment information on our servers. All transactions are processed through secure third-party payment gateways.</li>
                        <li>We may collect information about the device you use to access our website, including IP address, browser type, and operating system.</li>
                    </ul>

                    <div className="main-sub-lable">3. Information Sharing</div>
                    <ul className="legal-ul">
                        <li>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this policy or as required by law.</li>
                        <li>We may share information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you.</li>
                    </ul>

                    <div className="main-sub-lable">4. Cookies and Similar Technologies</div>
                    <ul className="legal-ul">
                        <li>We use cookies and similar technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.</li>
                    </ul>

                    <div className="main-sub-lable">5. Security</div>
                    <ul className="legal-ul">
                        <li>We implement a variety of security measures to protect the confidentiality and integrity of your personal information.</li>
                    </ul>

                    <div className="main-sub-lable">6. Your Choices</div>
                    <ul className="legal-ul">
                        <li>Access, correct, or delete your personal information.</li>
                        <li>Opt out of receiving promotional emails.</li>
                        <li>Disable cookies through your browser settings.</li>
                    </ul>

                    <div className="main-sub-lable">7. Children's Privacy</div>
                    <ul className="legal-ul">
                        <li>Our website is not directed at individuals under the age of 13. We do not knowingly collect personal information from children.</li>
                    </ul>

                    <div className="main-sub-lable">8. Changes to This Privacy Policy</div>
                    <ul className="legal-ul">
                        <li>We reserve the right to update or modify this Privacy Policy at any time without notice. Changes will be effective when posted on our website.</li>
                    </ul>

                    <div className="main-sub-lable">9. Contact Information</div>
                    <ul className="legal-ul">
                        <li>If you have any questions or concerns about our Privacy Policy, please contact us at:</li>
                    </ul>
                    <p>Shree Shree Hare Krishna Diamonds</p>
                    <p>Email Id: <a href="mailto:info@ssjewel.co.in">info@ssjewel.co.in</a>.</p>
                    <p>Phone number: <a href="tel:+919909457875">(+91) 990 945 7875</a>.</p>

              </div>
          </div>
      </section>

  
    </div>
  );
};

export default PagePrivacy;
