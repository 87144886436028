import React, { useRef, useState } from 'react';

// Slick SLider Files
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// Lightgellery FIlles
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';

import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';


import productimg1 from "../../images/products/Untitled-1.jpg"
import productimg2 from "../../images/products/Untitled-2.jpg"
import productimg3 from "../../images/products/Untitled-3.jpg"
import productimg4 from "../../images/products/Untitled-4.jpg"


interface ProductImageSlider2Props {
  images: string[];
}


const detaildemoimg = [
  {
    productimg: productimg1,
  },
  {
    productimg: productimg2,
  },
  {
      productimg: productimg3,
  },
  {
      productimg: productimg4,
  },
]

const ProductImageSlider2: React.FC<ProductImageSlider2Props> = ({ images }) => {
  const mainSlider = useRef<Slider>(null);
  const thumbnailSlider = useRef<Slider>(null);
  const [mainSliderIndex, setMainSliderIndex] = useState(0);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => {
      setMainSliderIndex(index);
      thumbnailSlider.current?.slickGoTo(index);
    },
  };

  const thumbnailSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
  };

  const handleThumbnailClick = (index: number) => {
    mainSlider.current?.slickGoTo(index);
  };

  return (
    <div className="product-slider-container">
      <Slider ref={mainSlider} {...sliderSettings} className="main-slider">
        {detaildemoimg.map((item, index) => (
          <div className="p-1" key={index}>

            {/* h-[340px]  */}
            <div
              className={`border border-[#f3f3f3] relative slider-item cursor-pointer thumbnail-item w-full h-[340px] rounded-xl overflow-hidden bg-[white] ${
                index === mainSliderIndex ? 'active' : ''
              }`}
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-contain object-center bg-[white]"
                src={item.productimg}
                alt={`Product ${index + 1}`}
              />
            </div>
          </div>
        ))}
      </Slider>

      <Slider ref={thumbnailSlider} {...thumbnailSettings} className="thumbnail-slider mt-3">
        {detaildemoimg.map((item, index) => (
          <div className="p-1" key={index}>
            <div
              className="thumbnail-item cursor-pointer w-full h-[100px] rounded-lg overflow-hidden bg-[white]"
              onClick={() => handleThumbnailClick(index)}
            >
              <img className="w-full h-full object-contain object-center" src={item.productimg} alt={`Thumbnail ${index + 1}`} />
            </div>
          </div>
        ))}
      </Slider>

      <LightGallery plugins={[lgZoom]}>
        {detaildemoimg.map((item, index) => ( 
          <a className="first:flex absolute left-[15px] top-[15px] w-9 h-9 flex items-center justify-center rounded-full bg-white hover:text-white hover:bg-custom-golden dark:bg-slate-900 text-neutral-700 dark:text-slate-200" key={index} href={item.productimg}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
            </svg>
          </a>
        ))}
      </LightGallery>
    </div>
  );
};

export default ProductImageSlider2;
