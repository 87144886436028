import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import React, { FC, useId, useEffect, useState } from "react";
import clientSayMain from "images/clientSayMain.png";
import clientSay1 from "images/clientSay1.png";
import clientSay2 from "images/clientSay2.png";
import clientSay3 from "images/clientSay3.png";
import clientSay4 from "images/clientSay4.png";
import clientSay5 from "images/clientSay5.png";
import clientSay6 from "images/clientSay6.png";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";
import { StarIcon } from "@heroicons/react/24/solid";
import axios from 'axios';
import API_HOST from '../../config/config';

export interface SectionClientSayProps {
  className?: string;
}

interface testimonial {
  id:number;
  title:string;
  name:string;
  star: number;
}

const SectionClientSay: FC<SectionClientSayProps> = ({ className = "" }) => {
  // const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-home-data',{
          nopaginate : '1',
          status : '1',
          order : 'desc',
          type:'testimonials'
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setProducts(response.data.data.testimonials);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    // if (!sliderRef.current) {
    //   return () => {};
    // }
    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 3,
        },
        1279: {
          gap: 20,
          perView: 3,
        },
        1023: {
          gap: 20,
          perView: 3,
        },
        768: {
          gap: 20,
          perView: 2,
        },
        500: {
          gap: 20,
          perView: 1.1,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [products, UNIQUE_CLASS]);

  // const renderBg = () => {
  //   return (
  //     <div className="hidden md:block">
  //       <img className="absolute top-9 -left-20" src={clientSay1} alt="" />
  //       <img
  //         className="absolute bottom-[100px] right-full mr-40"
  //         src={clientSay2}
  //         alt=""
  //       />
  //       <img
  //         className="absolute top-full left-[140px]"
  //         src={clientSay3}
  //         alt=""
  //       />
  //       <img
  //         className="absolute -bottom-10 right-[140px]"
  //         src={clientSay4}
  //         alt=""
  //       />
  //       <img
  //         className="absolute left-full ml-32 bottom-[80px]"
  //         src={clientSay5}
  //         alt=""
  //       />
  //       <img className="absolute -right-10 top-10 " src={clientSay6} alt="" />
  //     </div>
  //   );
  // };

  return (
    <div
      className={`pt-6 nc-SectionClientSay relative flow-root ${className} `}
      data-nc-id="SectionClientSay"
    >
      <Heading desc="Let's see what people think of SS Jewels" isCenter>
      Testimonials
        {/* Good news from far away 🥇 */}
      </Heading>
      <div className="relative">
        {/* {renderBg()} */}

        {/* <img className="mx-auto" src={clientSayMain} alt="" /> */}
        <div className={`mt-8 lg:mt-8 relative ${UNIQUE_CLASS}`}>
          {/* <img
            className="opacity-50 md:opacity-100 absolute -mr-16 lg:mr-3 right-full top-1"
            src={quotationImg}
            alt=""
          />
          <img
            className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
            src={quotationImg2}
            alt=""
          /> */}
          <div className="glide__track " data-glide-el="track">
             
            <ul className="glide__slides ">
              { products.length > 0 && products.map((item:testimonial) => (
                <li key={item.id} className="glide__slide flex flex-col items-center bg-[#f7f8f9] p-5 rounded-[16px]">
                  <span className="block text-[16px] leading-[24px]">{item.title}</span>
             
                  <div className="w-full flex space-x-0.5 mt-3 text-custom-golden">
                    {
                      1 <= item.star  && <StarIcon className="w-5 h-5" />
                    }
                    {
                      2 <= item.star  && <StarIcon className="w-5 h-5" />
                    }
                    {
                      3 <= item.star  && <StarIcon className="w-5 h-5" />
                    }
                    {
                      4 <= item.star && <StarIcon className="w-5 h-5" />
                    }
                    {
                      5 <= item.star && <StarIcon className="w-5 h-5" />
                    }
                  </div>

                  <span className="w-full flex items-center gap-3 mt-4 text-[16px] font-semibold">
                    <img className="w-[40px] h-[40px]" src={clientSay1} alt="" />
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
            
          </div>

          {/* --- skeletant-design --- */}
            <div className="skeletant-target hidden">
              <div className="skeletant-design ld-no-img h-[30px] mt-4"></div>
              <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
              <div className="skeletant-design ld-no-img w-[120px] mx-auto h-[24px] mt-8"></div>
              <div className="flex items-center justify-center mt-4">
                <div className="skeletant-design ld-no-img w-[24px] h-[24px] mx-1"></div>
                <div className="skeletant-design ld-no-img w-[24px] h-[24px] mx-1"></div>
                <div className="skeletant-design ld-no-img w-[24px] h-[24px] mx-1"></div>
                <div className="skeletant-design ld-no-img w-[24px] h-[24px] mx-1"></div>
                <div className="skeletant-design ld-no-img w-[24px] h-[24px] mx-1"></div>
              </div>
            </div>
          {/* --- skeletant-design --- */}

          <div
            className="mt-10 glide__bullets flex items-center justify-center"
            data-glide-el="controls[nav]"
          >
            { products.length > 0 && products.map((item:testimonial,index) => (
              <button
                key={item.id}
                className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionClientSay;
