import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";


const PageSitemap: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Return Policy || SS Jewels</title>
      </Helmet>

      <section className="pt-16 pb-16">
          <div className="lagle-container">
                <div className="lagle-heading">Return Policy</div>

                <span className="sitemap-heding">Usefull Links</span>
                <ul className="sitemap-ul">
                    <li>
                        <Link className="sitemap-link" to="/">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className="sitemap-link" to="/about">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg>
                            About
                        </Link>
                    </li>
                    <li>
                        <Link className="sitemap-link" to="/faqs">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg>
                            FAQs
                        </Link>
                    </li>
                    <li>
                        <Link className="sitemap-link" to="/return">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg>
                            Return Policy
                        </Link>
                    </li>
                    <li>
                        <Link className="sitemap-link" to="/privacy">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg>
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link className="sitemap-link" to="/terms">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg>
                            Terms & Conditions
                        </Link>
                    </li>
                    <li>
                        <Link className="sitemap-link" to="/contact">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg>
                            Contact
                        </Link>
                    </li>
                </ul>

          </div>
      </section>

  
    </div>
  );
};

export default PageSitemap;
