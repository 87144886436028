import React, { FC } from "react";
import { Helmet } from "react-helmet-async";


const PageTerms: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Terms & Conditions || SS Jewels</title>
      </Helmet>

      <section className="pt-16 pb-16">
          <div className="lagle-container">
              <div className="lagle-heading">Terms & conditions</div>
              <div className="legal-content">
                
                  <div className="main-sub-lable">1. Acceptance of Terms</div>
                  <p>By accessing and using this website, you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use this website.</p>

                  <div className="main-sub-lable">2. User Accounts</div>
                  <ul className="legal-ul">
                      <li>To make a purchase on this website, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account information.</li>
                      <li>You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</li>
                  </ul>

                  <div className="main-sub-lable">3. Products and Pricing</div>
                  <ul className="legal-ul">
                      <li>All product listings on this website are subject to availability. We reserve the right to discontinue or change product specifications and prices without notice.</li>
                      <li>Prices are listed in INR and are inclusive of applicable taxes unless otherwise stated. Shipping costs are additional and will be indicated at checkout.</li>
                  </ul>

                  <div className="main-sub-lable">4. Orders and Payments</div>
                  <ul className="legal-ul">
                      <li>When you place an order, you agree to provide accurate and complete information. We reserve the right to refuse or cancel any order for any reason.</li>
                      <li>Payment is due at the time of purchase.</li>
                      <li>SS Jewels uses secure payment gateways to process transactions, and we do not store your payment information.</li>
                  </ul>

                  <div className="main-sub-lable">5. Shipping and Delivery</div>
                  <ul className="legal-ul">
                      <li>Shipping costs and estimated delivery times are provided at checkout. Actual delivery times may vary.</li>
                      <li>SS Jewels is not responsible for delays in delivery caused by events beyond our control.</li>
                  </ul>

                  <div className="main-sub-lable">6. Returns and Refunds</div>
                  <ul className="legal-ul">
                      <li>Our return policy is outlined in our return policy</li>
                      <li>Refunds will be processed in accordance with our refund policy..</li>
                  </ul>

                  <div className="main-sub-lable">7. Intellectual Property</div>
                  <ul className="legal-ul">
                      <li>All content on this website, including text, graphics, logos, images, audio clips, and software, is the property of Shree Shree Hare Krishna Diamonds and is protected by intellectual property laws.</li>
                      <li>You may not reproduce, distribute, display, or create derivative works of any content on this website without our prior written permission.</li>
                  </ul>

                  <div className="main-sub-lable">8. Limitation of Liability</div>
                  <ul className="legal-ul">
                      <li>SS Jewels is not liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use this website.</li>
                      <li>We make no warranties, expressed or implied, regarding the accuracy, completeness, or reliability of the content on this website.</li>
                  </ul>

                  <div className="main-sub-lable">9. Governing Law</div>
                  <ul className="legal-ul">
                      <li>These terms and conditions are governed by the laws of the courts of India. Any disputes arising out of or related to these terms and conditions will be resolved in the courts of the courts of India.</li>
                  </ul>

                  <div className="main-sub-lable">10. Changes to Terms</div>
                  <ul className="legal-ul">
                      <li>SS Jewels reserves the right to update or modify these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically for changes.</li>
                      <li>By using this website after any such changes, you agree to comply with and be bound by the modified terms and conditions.</li>
                  </ul>
                  <p> Shree Shree Hare Krishna Diamonds</p>
                  <p>Email Id: <a href="mailto:info@ssjewel.co.in">info@ssjewel.co.in</a>.</p>
                  <p>Phone number: <a href="tel:+919909457875">(+91) 990 945 7875</a>.</p>

              </div>
          </div>
      </section>

  
    </div>
  );
};

export default PageTerms;
