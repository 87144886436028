import React from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>SS Jewels</title>
        <meta name="description" content="Welcome to Shree Shree Hare Krishna Diamonds. Shree Shree Hare Krishna Diamonds is a renowned name in the world of diamonds, symbolizing excellence and integrity. With a legacy of craftsmanship spanning decades, they offer exquisite diamonds of unparalleled quality, catering to the most discerning clientele worldwide. Their commitment to ethically sourced diamonds and exceptional customer service sets them apart as a trusted and esteemed provider in the industry." />
        <meta property="og:site_name" content="SS Jewels" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content='Welcome to Shree Shree Hare Krishna Diamonds. Shree Shree Hare Krishna Diamonds is a renowned name in the world of diamonds, symbolizing excellence and integrity. With a legacy of craftsmanship spanning decades, they offer exquisite diamonds of unparalleled quality, catering to the most discerning clientele worldwide. Their commitment to ethically sourced diamonds and exceptional customer service sets them apart as a trusted and esteemed provider in the industry.' />
        <meta property="og:title" content="SS Jewels" />
        <meta property="og:image" itemProp="image" content="https://new.pmcommu.in/og-img.jpg" />
        <meta property="og:image:url" content="https://new.pmcommu.in/og-img.jpg" />
        <meta property="og:image:secure_url" content="https://new.pmcommu.in/og-img.jpg" />
        <meta property="og:url" content={window.location.origin} />
      </Helmet>

      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
