// src/context/CartContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import NotifyAddTocart from "components/NotifyAddTocart";
import toast from "react-hot-toast";
import axios from 'axios';
import API_HOST from '../config/config';

const CartContext = createContext();

export const useCart = () => {
  
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export const CartProvider = ({ children }) => {
  const [settings, setSettings] = useState([]);
  const [shippingCharge, setShippingCharge] = useState([]);
  const [cart, setCart] = useState([]);
  const [lastAddedItem, setLastAddedItem] = useState(null);
  useEffect(() => {
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/settings',{
          nopaginate : '1',
          status : '1',
          order : 'desc',
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setSettings(response.data.data);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    const fetchShippingChargeData = async () => { 
      try {
        axios.post(API_HOST+'/api/get_shipping')
        .then(response => {
          if(response.data.success === 0)
          {
            setShippingCharge(response.data.data);
          }
        })
        .catch(error => {
          setShippingCharge([]);
          console.error('Error fetching data:', error);
        });
      } catch (error) {
        setShippingCharge([]);
        console.error('Error fetching data:', error);
      }
    };
  
    fetchShippingChargeData();
  }, []);

  useEffect(() => {
    // Retrieve cart data from local storage on component mount
    const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(savedCart);
  }, []);

  useEffect(() => {
    // Save cart data to local storage whenever it changes
    localStorage.setItem('cart', JSON.stringify(cart));
    if (lastAddedItem) {
        notifyAddTocart(lastAddedItem.id);
    }
  }, [cart, lastAddedItem]);

  const addToCart = (product,qty=1) => {
    const existingItem = cart.find((item) => item.id === product.id);

    if (existingItem) {
     setLastAddedItem(existingItem);
      setCart(
        cart.map((item) =>
          item.id === product.id ? { ...item, selected_attribute: (product?.selected_attribute ? product.selected_attribute : item?.selected_attribute), selected_color: (product?.selected_color ? product.selected_color : item?.selected_color),quantity: item.quantity + qty } : item
        )
      );
    } else {
      setCart([...cart, { ...product, quantity: qty }]);
      setLastAddedItem(product);
    }
  };

  const removeFromCart = (productId) => {
    setCart(cart.filter((item) => item.id !== productId));
  };

  const removeFromCartAllItem = () => {
    setCart([]);
  };

  const cartItemCount = cart.reduce((count, item) => count + item.quantity, 0);

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.quantity * ((item?.selected_attribute?.attribute_price) ? (item?.selected_attribute?.attribute_price) : (item.price)), 0);
  };

  const calculateTax = () => {
    return parseFloat((calculateTotal() * settings?.vat) / 100).toFixed(2);
  };

  const getVat = () => {
    return (settings?.vat ? settings?.vat : 0) ;
  };

  const getShippingCharge = () => {
    return (shippingCharge?.price ? parseFloat(shippingCharge?.price).toFixed(2) : 0.00);
  }

  const getShippingChargeId = () => {
    return (shippingCharge?.id ? shippingCharge?.id : '');
  }

  const calculateGrandTotal = () => {
    return parseFloat(parseFloat(calculateTotal()) + parseFloat(getShippingCharge()) + parseFloat(calculateTax())).toFixed(2);
  };

  const discountTotal = (discountDetail) => {
    if(discountDetail?.type=="fixed"){
        return (discountDetail?.value ? discountDetail?.value : 0);
    }
    else if(discountDetail?.type=="percent"){
        return ((discountDetail?.value ? discountDetail?.value : 0) /100) * calculateTotal();
    }
    else{
        return 0;
    }
  }


  const increaseQuantity = (productId) => {
    setCart(
      cart.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const decreaseQuantity = (productId) => {
    setCart(
      cart.map((item) =>
        item.id === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const getItemById = (productId) => {
    return cart.find((item) => item.id === productId);
  };

  const notifyAddTocart = (productId) => {
    const item = getItemById(productId);
    // console.log('product_id')
    // console.log(productId)
    // console.log('cart')
    // console.log(cart)
    // console.log('item')
    // console.log(item)
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={item.image}
          qualitySelected={item.quantity}
          show={t.visible}
          name={item.name}
          price={((item?.selected_attribute?.attribute_price) ? (item?.selected_attribute?.attribute_price) : (item.price))}
          //sizeSelected={sizeSelected}
          //variantActive={variantActive}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
    setLastAddedItem(null);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, removeFromCartAllItem, cartItemCount, calculateTotal, discountTotal, calculateTax, calculateGrandTotal, increaseQuantity, decreaseQuantity , getVat, getShippingCharge, getShippingChargeId }}>
      {children}
    </CartContext.Provider>
  );
};